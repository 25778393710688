import * as Sentry from '@sentry/react'
import React from 'react'
import {
  createBrowserRouter,
  createRoutesFromChildren,
  createRoutesFromElements,
  matchRoutes,
  Route,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { Layout } from 'components/Layout'
import { RoutePaths } from 'routes'

import { getUserId } from 'api/supabase'
import { getIsPremiumEntitled } from 'helpers/subscriptions'
import { BuildingPlan } from 'pages/BuildingPlan'
import { Checkout } from 'pages/Checkout'
import { Email } from 'pages/Email'
import { ForgotPassword } from 'pages/ForgotPassword'
import CancelationApplePage from 'pages/helpCenter/CancelationApplePage'
import CancelationCompletedPage from 'pages/helpCenter/CancelationCompletedPage'
import CancelationGooglePage from 'pages/helpCenter/CancelationGooglePage'
import CancelationPage from 'pages/helpCenter/CancelationPage'
import HelpCenterPage from 'pages/helpCenter/HelpCenterPage'
import RefundApplePage from 'pages/helpCenter/RefundApplePage'
import RefundCompletedPage from 'pages/helpCenter/RefundCompletedPage'
import RefundGooglePage from 'pages/helpCenter/RefundGooglePage'
import RefundPage from 'pages/helpCenter/RefundPage'
import { Login } from 'pages/Login'
import { Age } from 'pages/onboarding/age'
import { Build } from 'pages/onboarding/build'
import { DesireOnb1 } from 'pages/onboarding/desire'
import { DistractionsOnb1 } from 'pages/onboarding/distractions'
import { DriversOnb1 } from 'pages/onboarding/drivers'
import { Energy } from 'pages/onboarding/energy'
import { FeedbackOnb1 } from 'pages/onboarding/feedback'
import { Gender } from 'pages/onboarding/gender'
import { GetUp } from 'pages/onboarding/getUp'
import { Glad } from 'pages/onboarding/glad'
import { GoalsOnb1 } from 'pages/onboarding/goals'
import { Lifestyle } from 'pages/onboarding/lifestyle'
import { Motivation } from 'pages/onboarding/motivation'
import { Procrastrination } from 'pages/onboarding/procrastination'
import { ReadyOnb1 } from 'pages/onboarding/ready'
import { Sleep } from 'pages/onboarding/sleep'
import { StatementAnxiousOnb1 } from 'pages/onboarding/statementAnxious'
import { StatementConcentrationOnb1 } from 'pages/onboarding/statementConcentration'
import { StatementNotEnoughTimeOnb1 } from 'pages/onboarding/statementNotEnoughTime'
import { StatementRegretsOnb1 } from 'pages/onboarding/statementRegret'
import { Password } from 'pages/Password'
import PaymentConfirmation from 'pages/PaymentConfirmation'
import { Plans } from 'pages/Plans'
import { ResetPassword } from 'pages/ResetPassword'
import { ThankYou } from 'pages/ThankYou'
import { IsPremiumEntitledAndIsSignedIn, IsSignedIn } from 'types'
import { ProtectedRoute } from './ProtectedRoute'
import { RedirectRoute } from './RedirectRoute'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0, // NOTE: Disable performance monitoring
})

async function loadIsSignedIn(): Promise<IsSignedIn> {
  const userId = await getUserId()

  return { isSignedIn: !!userId }
}

async function loadIsPremiumEntitledAndIsSignedIn(): Promise<IsPremiumEntitledAndIsSignedIn> {
  const userId = await getUserId()
  const isPremiumEntitled = userId ? await getIsPremiumEntitled() : false

  return { isPremiumEntitled, isSignedIn: !!userId }
}

export const router = () => {
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  return sentryCreateBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path={RoutePaths.Root} element={<Layout />}>
          <Route path={RoutePaths.ForgotPassword} element={<ForgotPassword />} />
          <Route path={RoutePaths.Login} element={<Login />} />
          <Route path={RoutePaths.Password} element={<Password />} />
          <Route path={RoutePaths.Email} element={<Email />} />

          <Route
            path={RoutePaths.Root}
            element={<RedirectRoute />}
            loader={loadIsPremiumEntitledAndIsSignedIn}
          />
          {/* <Route path={RoutePaths.HelpCenter} element={<HelpCenterPage />} /> */}

          <Route element={<ProtectedRoute />} loader={loadIsSignedIn}>
            <Route path={RoutePaths.BuildingPlan} element={<BuildingPlan />} />
            <Route path={RoutePaths.Checkout} element={<Checkout />} />
            <Route path={RoutePaths.PaymentConfirmation} element={<PaymentConfirmation />} />
            <Route path={RoutePaths.ResetPassword} element={<ResetPassword />} />
            <Route path={RoutePaths.ThankYou} element={<ThankYou />} />
          </Route>

          <Route path={RoutePaths.HelpCenter} element={<HelpCenterPage />} />
          <Route path={RoutePaths.Cancelation} element={<CancelationPage />} />
          <Route path={RoutePaths.CancelationApple} element={<CancelationApplePage />} />
          <Route path={RoutePaths.CancelationGoogle} element={<CancelationGooglePage />} />
          <Route path={RoutePaths.CancelationCompleted} element={<CancelationCompletedPage />} />

          <Route path={RoutePaths.Refund} element={<RefundPage />} />
          <Route path={RoutePaths.RefundApple} element={<RefundApplePage />} />
          <Route path={RoutePaths.RefundGoogle} element={<RefundGooglePage />} />
          <Route path={RoutePaths.RefundCompleted} element={<RefundCompletedPage />} />

          {/* Onboarding */}
          <Route path={RoutePaths.Drivers} element={<Age />} loader={loadIsSignedIn} />
          <Route path={RoutePaths.Gender} element={<Gender />} />
          <Route path={RoutePaths.Glad} element={<Glad />} />
          <Route path={RoutePaths.Sleep} element={<Sleep />} />
          <Route path={RoutePaths.GetUp} element={<GetUp />} />
          <Route path={RoutePaths.Energy} element={<Energy />} />
          <Route path={RoutePaths.Lifestyle} element={<Lifestyle />} />
          <Route path={RoutePaths.GoalsOnb1} element={<GoalsOnb1 />} />
          <Route path={RoutePaths.Motivation} element={<Motivation />} />
          <Route path={RoutePaths.DistractionsOnb1} element={<DistractionsOnb1 />} />
          <Route path={RoutePaths.Procrastination} element={<Procrastrination />} />
          <Route path={RoutePaths.DriversOnb1} element={<DriversOnb1 />} />
          <Route path={RoutePaths.Building} element={<Build />} />
          <Route path={RoutePaths.DesireOnb1} element={<DesireOnb1 />} />
          <Route path={RoutePaths.StatementAnxiousOnb1} element={<StatementAnxiousOnb1 />} />
          <Route
            path={RoutePaths.StatementNotEnoughTimeOnb1}
            element={<StatementNotEnoughTimeOnb1 />}
          />
          <Route
            path={RoutePaths.StatementConcentrationOnb1}
            element={<StatementConcentrationOnb1 />}
          />
          <Route path={RoutePaths.StatementRegretnOnb1} element={<StatementRegretsOnb1 />} />
          <Route path={RoutePaths.ReadyOnb1} element={<ReadyOnb1 />} />
          <Route path={RoutePaths.FeedbackOnb1} element={<FeedbackOnb1 />} />
        </Route>
        , ,{/* NOTE: The plans page needs its custom layout */}
        <Route element={<ProtectedRoute />} loader={loadIsSignedIn}>
          <Route path={RoutePaths.Plans} element={<Plans />} />,
        </Route>
      </>,
    ),
  )
}
