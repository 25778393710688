import { trackEvent } from "api/supabase";
import { hashValue } from "helpers/hashValue";
import {
  getUserCity,
  getUserCountryCode,
  getUserEmail,
  getUserId,
  getUserZipCode,
} from "helpers/sessionStorage";
import Cookies from "js-cookie";
import { EventName, StripePaymentMethodType } from "supabase_types";
import { v4 as uuidv4 } from "uuid";
import { FACEBOOK_CLICK_ID_KEY } from "./localStorage";
import { getNetAmount } from "./getGeolocation";
import { getFxRate } from "./supabase";

export async function trackCompleteRegistrationEvent() {
  const eventId = createEventId();
  const eventName = "CompleteRegistration";

  console.log("TRACK REGISTRATION EVENT")

  await trackEventFromServer({ eventId, eventName });
  await trackEventFromClient({ eventId, eventName });
}

export async function trackSubscriptionEvent({
  isPurchase,
  currency,
  paymentMethodType,
}: {
  isPurchase: boolean;
  currency: string;
  paymentMethodType: StripePaymentMethodType;
}) {
  const sanitizedCurrency = sanitizeCurrency(currency)
  const fxRate = await getFxRate(currency)
  const clv_euro = 80.85 // gross CLV in euros
  const countryCode = await getUserCountryCode()

  let clv_currency = 0
  if (fxRate) {
    clv_currency = clv_euro * fxRate
  } else {
    clv_currency = clv_euro
  }

  const netAmount = await getNetAmount({
    countryCode,
    currency: sanitizedCurrency,
    grossAmount: clv_currency,
    paymentMethodType,
  })

  const eventId = createEventId()
  const eventName = isPurchase ? "Purchase" : "InitiateCheckout"

  await trackEventFromServer({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: netAmount,
  })

  await trackEventFromClient({
    currency: sanitizedCurrency,
    eventId,
    eventName,
    value: netAmount,
  })
}

function createEventId(): string {
  return uuidv4();
}

function sanitizeCurrency(currency: string): string {
  return currency.toUpperCase();
}

async function trackEventFromClient({
  currency,
  eventId,
  eventName,
  value,
}: {
  currency?: string;
  eventId: string;
  eventName: EventName;
  value?: number;
}) {
  const userCity = await getUserCity();
  const userCountryCode = (await getUserCountryCode()).toLowerCase();
  const userEmail = await getUserEmail();
  const userId = await getUserId();
  const userZipCode = await getUserZipCode();

  const payload =
    eventName === "CompleteRegistration" ? {} : { currency, value };

  return new Promise<void>((resolve, reject) => {
    if (fbq) {
      fbq("init", import.meta.env.VITE_FACEBOOK_PIXEL_ID, {
        country: userCountryCode,
        ct: userCity,
        em: userEmail,
        external_id: userId,
        zp: userZipCode,
      });

      fbq("track", eventName, payload, {
        eventID: eventId,
      });

      resolve();
    } else {
      reject(new Error("Facebook Pixel not loaded"));
    }
  });
}

async function trackEventFromServer({
  currency,
  eventId,
  eventName,
  value,
}: {
  currency?: string;
  eventId: string;
  eventName: EventName;
  value?: number;
}) {
  const eventSourceUrl = window.location.href;
  const fbc =
    Cookies.get("_fbc") ?? localStorage.getItem(FACEBOOK_CLICK_ID_KEY);
  const fbp = Cookies.get("_fbp");
  const userCity = await getUserCity();
  const userCountryCode = (await getUserCountryCode()).toLowerCase();
  const userEmail = await getUserEmail();
  const userId = await getUserId();
  const userZipCode = await getUserZipCode();

  await trackEvent({
    currency,
    eventId,
    eventName,
    eventSourceUrl,
    fbc,
    fbp,
    pixelId: import.meta.env.VITE_FACEBOOK_PIXEL_ID as string,
    userCity: await hashValue(userCity),
    userCountryCode: await hashValue(userCountryCode),
    userEmail: await hashValue(userEmail),
    userId: await hashValue(userId),
    userZipCode: await hashValue(userZipCode),
    value,
  });
}
