import appleTrust from 'assets/appleTrust.png'
import googleTrust from 'assets/googleTrust.png'
import { Accordion } from 'components/Accordion'
import { CTAButton } from 'components/CTAButton'
import { CheckIcon } from 'components/CheckIcon'
import getCurrencyAndPriceInCents from 'helpers/getCurrencyAndPriceInCents'
import useIntroPriceCoupon from 'hooks/useIntroPriceCoupon'
import { usePrices } from 'hooks/usePrices'
import { useUserLocale } from 'hooks/useUserLocale'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RoutePaths } from 'routes'
import { getUserCurrency } from 'helpers/sessionStorage'
import WeeklyPlan from './WeeklyPlan'

export const Plans = () => {
  const introPriceCoupon = useIntroPriceCoupon()
  const locale = useUserLocale()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { weeklyPrice, isLoading } = usePrices()

  const [weeklyPriceCurrency, setWeeklyPriceCurrency] = useState<string>('')
  const [userCurrency, setUserCurrency] = useState<string>('')
  const request = getUserCurrency()

  const [timeLeft, setTimeLeft] = useState('00:00')

  useEffect(() => {
    const startMinutes = 9
    setTimeLeft('09:00')

    const endTime = new Date().getTime() + startMinutes * 60 * 1000

    const timer = setInterval(() => {
      const now = new Date().getTime()
      const distance = endTime - now

      if (distance <= 0) {
        clearInterval(timer)
        setTimeLeft('00:00')
        return
      }

      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setTimeLeft(`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`)
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    request.then((currency) => {
      const { currency: newWeeklyPriceCurrency } =
        getCurrencyAndPriceInCents({
          stripePrice: weeklyPrice,
          userCurrency: currency,
        })
      // Store the results in states
      setUserCurrency(currency)
      setWeeklyPriceCurrency(newWeeklyPriceCurrency)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCurrency, weeklyPrice, introPriceCoupon])

  return (
    <>
      <div className="min-h-dvh animate-change-bg from-[#F5E1EF] to-[#DFF8FC]">
        {timeLeft !== '00:00' && (
          <div className="space-y-3 bg-blue-50">
            <div className="container mx-auto flex max-w-5xl grow flex-row justify-between px-10 py-4">
              <div className="flex flex-col">
                <h1 className="font-bold">{t('plans.reserve_90')}</h1>
                <h1 className="text-3xl font-bold">{timeLeft}</h1>
              </div>

              <button
                type="button"
                className={'my-2 rounded-full bg-[#222222] px-3 py-2 font-bold text-white'}
                onClick={() => {
                  navigate(RoutePaths.Checkout, {
                    state: {
                      couponId: introPriceCoupon?.id,
                      currency: weeklyPriceCurrency,
                      duration: 1,
                      priceId: weeklyPrice.id,
                    },
                  })
                }}
              >
                {t('plans.startNow')}
              </button>
            </div>
          </div>
        )}
        <div className="flex min-h-dvh flex-col bg-gradient-to-b from-white to-transparent pt-3">
          <div className="container mx-auto flex max-w-5xl grow flex-col justify-between px-10 py-10">
            <div className="space-y-3">
              <h1 className="text-center text-2xl font-bold">{t('plans.headline')}</h1>

              <h2 className="text-md text-center font-semibold text-[#BBBFCB]">
                {t('plans.subheadline')}
              </h2>
            </div>

            <div className="mt-9 grid grid-cols-1 items-end gap-9 md:grid-cols-1">
              <WeeklyPlan
                introPriceCoupon={introPriceCoupon}
                isLoading={isLoading}
                locale={locale}
                weeklyPrice={weeklyPrice}
                userCurrency={userCurrency}
                timeLeft={timeLeft}
              />
            </div>

            <div className="mt-12 space-y-11">
              {[1, 2, 3, 4].map((index) => (
                <div key={`benefit-${index}`} className="flex space-x-4">
                  <CheckIcon className="shrink-0" />

                  <div>
                    <p className="font-bold">{t(`plans.benefitHeader_${index}`)}</p>

                    <p>{t(`plans.benefitText_${index}`)}</p>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-12 space-y-4 text-center">
              <h2 className="text-2xl font-bold">{t('plans.trust_element.headline')}</h2>
              <div className="flex justify-center space-x-4">
                <img src={appleTrust} alt="Apple Logo" className="h-auto w-40" />
                <img src={googleTrust} alt="Google Logo" className="h-auto w-40" />
              </div>
              <p>{t('plans.trust_element.andHas')}</p>
              <p className="text-xl font-bold">{t('plans.trust_element.userAmount')}</p>
              <p className="text-xl font-bold">⭐️⭐️⭐️⭐️⭐️</p>
            </div>

            <div className="mt-12 space-y-4">
              <h2 className="text-center text-2xl font-bold">{t('plans.faq.headline')}</h2>
              <Accordion title={t('plans.faq.firstHeadline')}>
                <p>{t('plans.faq.firstDescription')}</p>
              </Accordion>
              <Accordion title={t('plans.faq.secondHeadline')}>
                <p>{t('plans.faq.secondDescription')}</p>
              </Accordion>
              <Accordion title={t('plans.faq.thirdHeadline')}>
                <p>{t('plans.faq.thirdDescription')}</p>
              </Accordion>
            </div>

            <div className="mt-12 space-y-4">
              <CTAButton
                text={t('plans.cta')}
                onClick={() =>
                  navigate(RoutePaths.Checkout, {
                    state: {
                      couponId: introPriceCoupon?.id,
                      currency: weeklyPriceCurrency,
                      duration: 1,
                      priceId: weeklyPrice.id,
                    },
                  })
                }
              />

              <p className="text-center font-bold">{t('plans.cancel')}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
