import { AuthError, createClient, User } from "@supabase/supabase-js";
import {
  CancelRevenueCatSubscriptionsData,
  EdgeFunctionError,
  RefundRevenueCatSubscriptionsData,
} from "supabase_types";

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL as string;
const supabaseKey = import.meta.env.VITE_SUPABASE_KEY as string;

const supabase = createClient(supabaseUrl, supabaseKey);

export async function cancelRevenueCatSubscriptions(): Promise<CancelRevenueCatSubscriptionsData> {
  const response = await supabase.functions.invoke(
    "cancel-revenuecat-subscriptions",
  );

  const data = response.data as CancelRevenueCatSubscriptionsData;
  const error = response.error as EdgeFunctionError;

  if (error) throw error;

  return data;
}

export async function getUser(): Promise<{
  data: { user: User | null };
  error: AuthError | null;
}> {
  const { data, error } = await supabase.auth.getUser();

  return { data, error };
}

export async function refundRevenueCatSubscriptions(): Promise<RefundRevenueCatSubscriptionsData> {
  const response = await supabase.functions.invoke(
    "refund-revenuecat-subscriptions",
  );

  const data = response.data as RefundRevenueCatSubscriptionsData;
  const error = response.error as EdgeFunctionError;

  if (error) throw error;

  return data;
}

export async function getFxRate(currency: string): Promise<number | null> {
  const { error, data } = await supabase
    .from("fx_rates")
    .select("*")
    .order("created_at", { ascending: false })
    .limit(1)

  if (error) {
    console.error("error", error, data)
    return null
  }

  return data[0][currency.toLowerCase()] as number ?? null
}
