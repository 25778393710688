import { useQuery } from '@tanstack/react-query'
import { getRevenueCatSubscriber } from 'helpers/subscriptions'
import { useSupabase } from 'hooks/useSupabase'
import { StripeCoupon } from 'types'

export default function useIntroPriceCoupon(): StripeCoupon | undefined {
  const { data: revenueCatSubscriber } = useQuery({
    queryKey: ['revenueCatSubscriber'],
    queryFn: getRevenueCatSubscriber,
  })

  const supabase = useSupabase()

  const coupons = useQuery({
    queryKey: ['stripeCoupons'],
    queryFn: () => supabase.functions.invoke('list-stripe-coupons'),
  })

  const hasSubscribedBefore = revenueCatSubscriber?.entitlements?.['Premium']?.expires_date

  if (hasSubscribedBefore) return

  const introPriceCoupon = coupons?.data?.data?.find(
    (coupon: StripeCoupon) => coupon.id === 'intro-price-95',
  )

  return introPriceCoupon
}
