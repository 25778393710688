import { createClient } from '@supabase/supabase-js'
import { EdgeFunctionError, EventName, GetStripePaymentMethodTypeResponse } from 'supabase_types'

export const getUserId = async () => {
  const { data } = await supabaseClient.auth.getSession()
  return data?.session?.user?.id
}

export const supabaseClient = createClient(
  import.meta.env.VITE_SUPABASE_URL,
  import.meta.env.VITE_SUPABASE_KEY,
)

// export async function trackConfidenceEvent({
//   visitorId,
//   value,
// }: {
//   visitorId: string;
//   value: string;
// }) {
//   await supabaseClient.from("confidence_tmp").insert({
//     vistor_id: visitorId,
//     flag_value: value,
//     was_received: value !== "123"
//   });
// }

export async function trackEvent({
  currency,
  eventId,
  eventName,
  eventSourceUrl,
  fbc,
  fbp,
  pixelId,
  userCity,
  userCountryCode,
  userEmail,
  userId,
  userZipCode,
  value,
}: {
  currency?: string;
  eventId: string;
  eventName: EventName;
  eventSourceUrl: string;
  fbc: string | undefined | null;
  fbp: string | undefined;
  pixelId: string;
  userCity: string | undefined;
  userCountryCode: string | undefined;
  userEmail: string | undefined;
  userId: string | undefined;
  userZipCode: string | undefined;
  value?: number;
}) {
  const response = await supabaseClient.functions.invoke("track-event", {
    body: {
      currency,
      eventId,
      eventName,
      eventSourceUrl,
      fbc,
      fbp,
      pixelId,
      userCity,
      userCountryCode,
      userEmail,
      userId,
      userZipCode,
      value,
    },
  });

  const error = response.error as EdgeFunctionError;

  if (error) {
    console.error(error);
  }
}

export async function getStripePaymentMethodType(
  paymentMethodId: string,
): Promise<GetStripePaymentMethodTypeResponse> {
  const response = await supabaseClient.functions.invoke(
    "get-stripe-payment-method-type",
    {
      body: {
        paymentMethodId,
      },
    },
  );

  const data = response.data as GetStripePaymentMethodTypeResponse;
  const error = response.error as EdgeFunctionError;

  if (error) {
    console.error("error", error);
  }

  return data;
}